.buttonContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
}
.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  border: 1.8px solid rgba(208, 220, 231, 0.6);
  border-radius: 110px;

  padding: 8px 8px;
  padding-left: 15px;

  position: relative;
  min-width: 160px;
}
.networkButtton {
  min-width: 290px;
}
.label {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 100%;
  text-align: center;
  color: rgb(181, 198, 214, 0.6);
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-70%);
  background-color: #1e1e1e;
  padding: 0 5px;
}
.buttonText {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: rgb(181, 198, 214, 0.6);
  text-transform: capitalize;
}
.arrow {
  color: rgb(181, 198, 214, 0.6);
  font-size: 20px;
  cursor: pointer;
}
.dropDownItems {
  position: absolute;
  right: 0;
  top: 42px;
  z-index: 5;
  min-width: 100%;
  background-color: #1e1e1e;
  border: 1.8px solid rgba(208, 220, 231, 0.6);
  border-radius: 20px;
  padding: 8px 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.dropDownItem {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: rgb(181, 198, 214, 0.6);
  padding: 5px 0;
  text-transform: capitalize;
  cursor: pointer;
}
@media only screen and (max-width: 991px) {
  .buttonContainer {
    gap: 15px;
  }
}
@media only screen and (max-width: 400px) {
  .buttonContainer {
    flex-direction: column;
    width: 100%;
    gap: 15px;
  }
  .button {
    width: 100%;
  }
}
