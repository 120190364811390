.wrapper {
  background: #2e3443;
  border-radius: 30px;
  padding: 18px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.likeCommentContainer,
.socialContainer {
  display: flex;
  align-items: center;
  gap: 6px;
}
.likeCommentShareBox {
  background: rgba(208, 220, 231, 0.1);
  border-radius: 23px;
  padding: 5px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 38px;
}

.icon {
  width: 15px;
}
.number {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  text-transform: capitalize;
  color: rgb(208, 220, 231, 0.8);
}
.socialItem {
  background: rgba(208, 220, 231, 0.1);
  mix-blend-mode: normal;
  border-radius: 23px;
  height: 38px;
  width: 38px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.socialItem .icon {
  width: 22px;
}
@media only screen and (max-width: 520px) {
  .wrapper {
    padding: 12px;
    flex-direction: column;
    gap: 15px;
  }
}
