.tokenCard {
  background: var(--dark-2);
  border-radius: 30px;
}
.tokenCard .tokenCardTopArea {
  padding: 24px 24px 16px;
}
.description {
  min-height: 80px;
}
.description p {
  font-family: "Peyda";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 200%;
  text-transform: capitalize;
  color: #d0dce7;
  opacity: 0.8;
  margin-top: 16px;
  letter-spacing: 0.2px;
}
.tokenCardTop {
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 21px;
}
.kycAndAudit {
  display: flex;
  align-items: center;
  gap: 12px;
}
.kycAndAudit span {
  display: flex;
  align-items: center;
  padding: 6px 11px 4px;
  border-radius: 14px;
  gap: 8px;
  background: var(--dark-3);
  margin: 0;
}
.kycAndAudit span svg {
  transform: translateY(-2px);
}
.category {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 133%;
  text-align: center;
  color: #00e9ae;
  opacity: 0.5;
  background: #232b32;
  border-radius: 130px;
  padding: 4px 10px;
  text-transform: uppercase;
}
.tokenCardMiddle {
  background: var(--dark-3);
  border-radius: 24px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.logoAndStatus {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.status {
  border-radius: 130px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
}
.live {
  color: #232b32;
  background: #22c55c;
}
.upComing {
  background: #d173c8;
  color: #ffffff;
}
.ended {
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
}
.logoAndTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.logoContainer {
  width: 74px;
  height: 70px;
  background: linear-gradient(226.4deg, #56d984 -25.4%, #196d37 141.27%);
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo {
  width: 74px;
  height: 70px;
  object-fit: contain;
}

.cont {
  width: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.subtitle {
  font-size: 20px;
}
.subtitle a {
  color: var(--title);
}
.subtitle a:hover {
  color: var(--base);
}

.infoGrp {
  display: grid;
  grid-template-columns: 1fr 1fr;

  gap: 13px;
}

.badge {
  width: 100%;
  color: rgba(208, 220, 231, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background: var(--dark-2);
  border-radius: 60px;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 6px 10px;
}
.badge span {
  transform: translateY(2px);
  width: 0;
  flex-grow: 1;
  text-transform: capitalize;
  color: rgba(208, 220, 231, 0.6);
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  margin: 0;
}

.chain {
  text-align: center;
}
.chain .chainText {
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
}
.hepton {
  background: #000000;
}
.bsc {
  background: #f0a500;
}
.erc {
  background: #059dff;
}

.hepton .chainText {
  color: #fff;
  font-weight: 600;
}
.chainIcon {
  width: 15px;
}

.bsc .chainText {
  color: #000;
  font-weight: 600;
  margin-bottom: 2px;
}
.erc .chainText {
  color: #000;
  font-weight: 600;
}

.tokenCardMiddle .badge.badgeMd span {
  font-size: 16px;
}
.tokenCard .info li {
  display: flex;
  justify-content: space-between;
  padding: 15px 24px 13px;
  line-height: 14px;
  border-bottom: 1px solid rgba(208, 220, 231, 0.1);

  font-family: "Peyda";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: #d0dce7;
  opacity: 0.5;
}
.tokenCard .info li:first-child {
  border-top: 1px solid rgba(208, 220, 231, 0.1);
}
.tokenCardBottom {
  padding: 26px 24px 24px;
  font-size: 14px;
  font-weight: 500;
}
.round {
  font-family: "Poppins";
  font-style: normal;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  color: #d0dce7;

  opacity: 0.5;
}

.countdown {
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 500;
}
.countdown .subtitle {
  font-family: "Inter";

  background: var(--dark-3);
  font-size: 10px;
  line-height: 12px;
  padding: 6px 4px 2px;
  text-align: center;
  border-radius: 10px;
  width: 32px;
  text-align: center;
}
.countdown span:not(.subtitle) {
  transform: translateY(2px);
}
.myProgress {
  padding: 4px;
  border-radius: 20px;
  background: rgba(32, 33, 44, 0.9);
  margin: 12px 0;
  height: 28px;
}
.myProgress .progressInner {
  background: var(--base);
  background: linear-gradient(212.19deg, #56d984 -20.22%, #196d37 121.54%);
  border-radius: 20px;
}
.raisedContainer {
  display: flex;
  align-items: center;
  gap: 15px;
}
/* @media (max-width: 374px) {
  .tokenCardTop {
    margin-bottom: 12px;
  }
  .tokenCardMiddle {
    padding: 10px;
  }
  .tokenCardMiddle .logoAndTitle {
    width: 70px;
  }
  .tokenCardMiddle .cont .subtitle {
    font-size: 18px;
  }
  .tokenCardBottom {
    padding: 16px;
  }
  .tokenCard .tokenCardTopArea {
    padding: 16px;
  }
} */
@media only screen and (max-width: 374px) {
  .tokenCard .tokenCardTopArea {
    padding: 20px 10px 10px;
  }
  .tokenCardMiddle {
    padding: 10px 12px;
  }
  .logoContainer {
    width: 60px;
    height: 60px;
  }
  .cont {
    gap: 2px;
  }
  .infoGrp {
    gap: 8px;
  }
  .tokenCard .info li {
    padding: 15px 12px 13px;
  }
  .tokenCardBottom {
    padding: 16px 12px 20px;
  }
}
