@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&family=Poppins:wght@300;400;500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h5,
p {
  margin: 0;
}
span {
  margin-top: 4px;
  display: inline-block;
}
input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
