.nftDetails {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px 0;
}
.container {
  max-width: 1108px;
  width: 95%;
  margin: 0 auto;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media only screen and (max-width: 991px) {
  .nftDetails {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
  }
}
