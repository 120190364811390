@mixin breakpoint($point) {
  @if $point==sm {
    @media (min-width: 576px) {
      @content;
    }
  } @else if $point==max-sm {
    @media (max-width: 575px) {
      @content;
    }
  } @else if $point==md {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $point==max-md {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $point==lg {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $point==max-lg {
    @media (max-width: 991px) {
      @content;
    }
  } @else if $point==xl {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $point==max-xl {
    @media (max-width: 1199px) {
      @content;
    }
  } @else if $point==xxl {
    @media (min-width: 1400px) {
      @content;
    }
  } @else if $point==max-xxl {
    @media (max-width: 1399px) {
      @content;
    }
  }
}

@font-face {
  font-family: "Peyda";
  src: url(../src/assets/fonts/Peyda-Black.ttf) format(woff);
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: "Peyda";
  src: url(../src/assets/fonts/Peyda-ExtraBold.ttf) format(woff);
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: "Peyda";
  src: url(../src/assets/fonts/Peyda-Bold.ttf) format(woff);
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Peyda";
  src: url(../src/assets/fonts/Peyda-SemiBold.ttf) format(woff);
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Peyda";
  src: url(../src/assets/fonts/Peyda-Medium.ttf) format(woff);
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Peyda";
  src: url(../src/assets/fonts/Peyda-Regular.ttf) format(woff);
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Peyda";
  src: url(../src/assets/fonts/peyda-light.ttf) format(woff);
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Peyda";
  src: url(../src/assets/fonts/peyda-extralight.ttf) format(woff);
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Peyda";
  src: url(../src/assets/fonts/Peyda-Thin.ttf) format(woff);
  font-style: normal;
  font-weight: 100;
}

:root {
  --title: #d0dce7;
  --title-rgb: 208, 220, 231;
  --text: #ffffffb3;
  --body: #12131b;
  --section: #20212c;
  --dark: #232b32;
  --dark-2: #2e3443;
  --dark-3: #404656;
  --white: #ffffff;
  --base: #22c55c;
  --btn-hover: rgba(208, 220, 231, 0.1);
  --redish: #f563a9;
  --body-fonts: "Peyda";
  --heading-fonts: "Peyda";
  --border: rgba(208, 220, 231, 0.15);
  --fontFamily: "Peyda";
}

body {
  background: var(--body);
  font-family: var(--body-fonts);
  color: var(--text);
  font-size: 16px;
  line-height: 27.5px;
  font-weight: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--title);
  font-family: var(--heading-fonts);
  font-weight: 700;
}

h1 {
  font-size: 42px;
}
h2 {
  font-size: 34px;
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 18px;
}
@include breakpoint(max-md) {
  h1 {
    font-size: 34px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 22px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 16px;
  }
}
@include breakpoint(max-sm) {
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 16px;
  }
}
ul {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
  }
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  transition: all ease 0.3s;
}
.font-light {
  font-weight: 300;
}
.font-regular {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.font-extra-bold {
  font-weight: 800;
}
.font-black {
  font-weight: 900;
}
.container {
  position: relative;
  z-index: 1;
}
@include breakpoint(xl) {
  .container {
    max-width: 1146px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.bg-section {
  background: var(--section) !important;
}
.bg-body {
  background: var(--body) !important;
}
.cmn-btn {
  color: var(--dark);
  padding: 15px 22px 11px;
  display: flex;
  gap: 6px;
  align-items: center;
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-transform: capitalize;
  border-radius: 25px;
  background: var(--base);
  border: none;
  outline: none;
  box-shadow: none;
  transition: all ease 0.3s;
  text-align: center;
  border: 1px solid var(--base);
  &:hover {
    background: var(--btn-hover);
    color: var(--white);
    border-color: var(--base);
  }
  @include breakpoint(max-sm) {
    padding: 10px 18px;
    font-weight: 600;
  }
  &.secondary-btn {
    background: var(--btn-hover);
    color: var(--white);
    border-color: var(--btn-hover);
    &:hover {
      background: var(--base);
      color: var(--dark);
    }
  }
  &.btn-outline {
    background: var(--body);
    color: var(--white);
    border: 1px solid var(--base);
    &:hover {
      background: var(--base);
      border-color: var(--base);
      color: var(--dark);
    }
  }
  &.icon-btn {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 40px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
/*Header*/
.header-wrapper {
  padding: 18px 0;
  display: flex;
  align-items: center;
  gap: 8px;
  .logo {
    display: block;
    img {
      height: 50px;
      width: 148px;
      object-fit: contain;
      @include breakpoint(max-md) {
        height: 45px;
      }
    }
  }
  .menu-area {
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: 8px;
    column-gap: 16px;
    .menu {
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin-left: auto;
      margin-right: auto;
      li {
        a {
          font-size: 16px;
          line-height: 24px;
          color: #d0dce7;
          padding: 8px;
          border-bottom: 1px solid transparent;
          font-weight: 500;
          &:hover,
          &.active {
            border-color: var(--white);
          }
        }
      }
    }
    @include breakpoint(lg) {
      @include breakpoint(max-xl) {
        column-gap: 8px;
        .menu {
          column-gap: 8px;
        }
      }
    }
    .cmn-btn {
      padding: 13px 30px;
    }
    @include breakpoint(max-xl) {
      .menu {
        li {
          a {
            font-size: 14px;
            // font-family: var(--peyda-semibold);
            @include breakpoint(lg) {
              font-size: 13px;
            }
          }
        }
      }
    }
    .close {
      display: none;
    }
    @include breakpoint(max-lg) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 340px;
      height: 100vh;
      background: var(--section);
      z-index: 999;
      flex-direction: column;
      padding: 50px 20px 30px;
      justify-content: flex-start;
      align-items: flex-start;
      transition: all ease 0.4s;
      .close {
        position: absolute;
        right: 15px;
        top: 10px;
        width: unset;
        display: block;
      }
      > * {
        width: 100%;
      }
      .search-form {
        margin: 0;
        flex-grow: 0;
        .form-control {
          height: 46px;
          border-radius: 23px;
        }
      }
      .menu {
        width: 100%;
        flex-direction: column;
        margin: 45px 0;
        gap: 6px;
        li {
          width: 100%;
          text-align: center;
          a {
            display: inline-block;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
      &:not(.active) {
        transform: translateX(-100%);
      }
    }
  }
  .bar {
    display: none;
    @include breakpoint(max-lg) {
      display: block;
    }
  }
}
.header-btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  .cmn-btn {
    color: var(--dark);
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 6px !important;
    border: 1px solid var(--border);
    border-radius: 25px;
    &:hover {
      color: var(--white);
      border-color: var(--border);
      svg {
        path {
          fill: var(--white);
        }
      }
    }
    span {
      padding: 0 12px 0 8px;
    }
    // @include breakpoint(lg) {
    @include breakpoint(max-xl) {
      font-size: 13px;
      padding: 5px 12px;
      svg {
        width: 26px;
        height: 26px;
      }
    }
    // }
  }
  @include breakpoint(max-lg) {
    .cmn-btn {
      flex-grow: 1;
    }
    .__dropdown {
      flex-grow: 1;
    }
  }
}
header {
  position: sticky;
  top: 0;
  z-index: 999;
  background: var(--body);
  // border-bottom: 1px solid rgba(207, 219, 213, 0.1);
  .container {
    @include breakpoint(xl) {
      max-width: 1132px;
    }
  }
  @include breakpoint(max-lg) {
    .__dropdown {
      flex-grow: 1;
    }
  }
}
.__dropdown {
  position: relative;
  cursor: pointer;
  &-active {
    // background: var(--base);
    border: 1px solid var(--border);
    color: var(--white);
    display: flex;
    align-items: center;
    padding: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    min-width: 135px;
    min-height: 50px;
    border-radius: 25px;
    @include breakpoint(max-lg) {
      justify-content: center;
    }
    span {
      padding-right: 18px;
      white-space: nowrap;
      // margin-right: auto;
    }
    svg {
      path {
        stroke: #ffffff;
      }
    }
    // @include breakpoint(lg) {
    @include breakpoint(max-xl) {
      min-width: 100px;
      font-size: 12px;
      min-height: 40px;
    }
    // }
  }
  &.select-base {
    .__dropdown-active {
      background: var(--base);
      svg {
        path {
          stroke: #130f26;
        }
      }
    }
  }
  img {
    width: 38px;
    aspect-ratio: 1;
    object-fit: cover;
    margin-right: 13px;
    border-radius: 50%;
    // @include breakpoint(lg) {
    @include breakpoint(max-xl) {
      width: 26px;
    }
    // }
  }
  &-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background: var(--section);
    border: 1px solid rgba(#ffffff, 0.2);
    width: 100%;
    min-width: 220px;
    border-radius: 8px;
    z-index: 999;
    li {
      padding: 6px 15px;
      &.active {
        background: var(--base);
      }
      &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
    transition: all ease 0.3s;
    &:not(.active) {
      opacity: 0;
      visibility: hidden;
      transform: translateY(10px);
    }
  }
}
.brightness-icon {
  // @include breakpoint(lg) {
  @include breakpoint(max-xl) {
    svg {
      width: 40px;
    }
  }
  // }
}
.__gap-2 {
  gap: 16px;
  @include breakpoint(max-xl) {
    gap: 8px;
  }
}

.footer-bottom {
  padding: 19px 0;
  background: var(--body);
}
.footer-bottom-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  row-gap: 10px;
  column-gap: 22px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.5);
  .lang-area {
    flex-grow: 1;
  }
  .links {
    display: flex;
    flex-wrap: wrap;
    gap: 22px;
    justify-content: center;
    li {
      a {
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        color: var(--white);
        &:hover {
          color: var(--base);
        }
      }
    }
  }
  @include breakpoint(md) {
    justify-content: space-between;
  }
}
.__lang-control {
  position: relative;
  display: inline-flex;
  .form-control {
    -webkit-appearance: none;
    background: transparent;
    width: unset;
    color: var(--white);
    border: none;
    outline: none;
    height: 46px;
    box-shadow: none;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    padding-right: 22px;
    option {
      background: var(--body);
      color: var(--title);
    }
  }
  .globe-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  }
}
.lang-area {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 18px;
  @include breakpoint(max-md) {
    justify-content: center;
    width: 100%;
  }
}
.color-theme-icon {
  width: 46px;
  height: 46px;
  // border: 1px solid rgba(207, 219, 213, 0.15);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: var(--base);
    // border-color: var(--base);
  }
  @include breakpoint(max-sm) {
    width: 38px;
  }
}
.footer-widget {
  .title {
    margin-bottom: 18px;
    font-weight: 700;
  }
}
.__subscribe-form {
  position: relative;
  .form-control {
    border: 1px solid rgba(#cfdbd5, 0.15);
    border-radius: 24px;
    background: transparent;
    color: var(--white);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    height: 48px;
    padding-left: 20px;
    box-shadow: none;
    padding-right: 45px;
    &:focus {
      border-color: var(--base);
    }
    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }
  .icon {
    position: absolute;
    right: 6px;
    top: 6px;
    height: calc(100% - 12px);
    // transform: translateY(-50%);
    // z-index: -1;
    border-radius: 25px;
    padding: 0 12px;
    background: rgba(var(--title-rgb), 0.7);
    border: none;
    color: var(--dark);
  }
}
.mt-57 {
  margin-top: 57px;
  @include breakpoint(max-xl) {
    margin-top: 35px;
  }
}
.social-icons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  li {
    a {
      width: 46px;
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid var(--border);
      &:hover,
      &.active {
        background: rgba(235, 240, 240, 0.1);
        border-color: transparent;
      }
      @include breakpoint(max-sm) {
        width: 42px;
      }
    }
  }
  &.size--small {
    @include breakpoint(xl) {
      gap: 16px;
    }
    li {
      a {
        width: 34px;
        svg {
          width: 18px;
        }
        background: rgba(235, 240, 240, 0.1);
        border: none;
      }
    }
  }
}
.footer-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 95px;
  row-gap: 35px;
  .footer-widget {
    &.widget-about {
      width: 100%;
      margin-right: auto;
      @include breakpoint(sm) {
        max-width: 301px;
      }
    }
    &.widget-link {
      width: 100%;
      max-width: 130px;
      ul {
        display: flex;
        flex-direction: column;
        gap: 1px;
        li {
          a {
            font-weight: 500;
            color: rgba(var(--title-rgb), 0.5);
            line-height: 34px;
            &:hover {
              color: var(--base);
            }
          }
        }
      }
    }
    color: rgba(var(--title-rgb), 0.5);
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    &.widget-region {
      width: 100%;
      max-width: 270px;
      .text {
        margin-bottom: 25px;
      }
    }
  }
  @include breakpoint(max-xl) {
    column-gap: 40px;
    .footer-widget {
      &.widget-region {
        max-width: 240px;
      }
      &.widget-link {
        max-width: 110px;
        ul {
          li {
            a {
              font-weight: 600;
            }
          }
        }
      }
      &.widget-about {
        max-width: 270px;
        margin: 0;
      }
    }
  }
  @include breakpoint(max-lg) {
    column-gap: 40px;
    .footer-widget {
      &.widget-region {
        max-width: 100%;
      }
    }
  }
  @include breakpoint(max-md) {
    column-gap: 50px;
    justify-content: flex-start;
    .footer-widget {
      &.widget-about {
        max-width: 100%;
      }
      &.widget-link {
        max-width: 130px;
        ul {
          li {
            a {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  @include breakpoint(max-sm) {
    column-gap: 30px;
  }
}
.currency-select {
  background: transparent;
  border: 1px solid var(--border);
  border-radius: 25px;
  color: var(--white);
  padding: 11px 15px;
  option {
    background: var(--body);
  }
}
.footer-top {
  padding: 66px 0 51px;
  @include breakpoint(xl) {
    padding-top: 135px;
    padding-bottom: 85px;
  }
}
.antex-ui-section {
  padding: 60px 0 90px;
  background: var(--dark-2);
}
.antex-ui-card {
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  padding: 58px 45px 18px;
  background: #394256;
  .antex-ui-shape {
    position: absolute;
    inset-inline-end: 0;
    inset-block-end: 0;
  }
  .inner {
    position: relative;
    z-index: 1;
  }
  .title {
    font-weight: 900;
    font-size: 24.1042px;
    line-height: 162%;
    margin: 0;
    /* or 39px */

    letter-spacing: -0.02em;
    a {
      color: var(--white);
    }
  }
  .subtitle {
    margin-top: -4px;
    margin-bottom: 21px;
    font-weight: 300;
    font-size: 11.8667px;
    line-height: 162%;
  }
  .status {
    font-weight: 800;
    font-size: 6.19006px;
    line-height: 8px;
    background: rgba(226, 226, 226, 0.09);
    border-radius: 0px 5.41631px 5.41631px 0px;
    display: inline-flex;
    .icon {
      background: #f99030;
      display: block;
      padding: 3px;
      border-radius: 7px 0 0 7px;
    }
    border-radius: 7px;
    .txt {
      padding: 5px 5.8px;
      display: block;
      align-self: center;
    }
  }
  @include breakpoint(max-sm) {
    padding: 45px 25px 20px;
    .title {
      font-weight: 700;
    }
  }
}

.slick-dots {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 6px;
  row-gap: 4px;
  bottom: -28px;
  left: 48px;
  width: calc(100% - 96px);
  li {
    padding: 0;
    margin: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #ebf0f0;
    opacity: 0.1;
    transition: all ease 0.3s;
    padding: 0;
    margin: 0;
    border-radius: 4px;
    button {
      padding: 0;
      margin: 0;
      width: 8px;
      height: 8px;
      opacity: 0;
    }
    &.slick-active {
      opacity: 1;
      background: var(--base);
      width: 16px;
    }
  }
  @include breakpoint(max-sm) {
    left: 36px;
    width: calc(100% - 72px);
  }
}
.slick-list {
  margin: -12px;
  .slick-slide {
    padding: 12px;
  }
}
.section-gap {
  padding: 90px 0;
  @include breakpoint(max-md) {
    padding: 60px 0;
  }
}
.cta-wrapper {
  padding: 44px;
  background: var(--section);
  border-radius: 30px;
  display: flex;
  flex-wrap: wrap;
  .cta-content {
    max-width: 525px;
    .btn-grp {
      gap: 16px;
      display: flex;
      flex-wrap: wrap;
      .cmn-btn {
        min-width: 143px;
      }
    }
    .title {
      margin-bottom: 19px;
    }
    font-size: 18px;
    line-height: 185.5%;
    p {
      font-weight: 300;
    }
    @include breakpoint(md) {
      .title {
        font-size: 40px;
        line-height: 56px;
      }
      p {
        margin-bottom: 30px;
      }
    }
  }
  .cta-thumb {
    @include breakpoint(lg) {
      width: 1px;
      height: 1px;
      img {
        max-width: 525px;
        position: absolute;
        right: -10px;
        bottom: -44px;
      }
    }
  }
  @include breakpoint(max-xl) {
    .cta-content {
      max-width: 450px;
      position: relative;
      z-index: 1;
      .title {
        font-size: 36px;
        line-height: 1.3;
      }
    }
    .cta-thumb {
      img {
        width: 100%;
        max-width: 425px;
      }
    }
  }
  @include breakpoint(max-lg) {
    justify-content: center;
    text-align: center;
    gap: 30px;
    .btn-grp {
      justify-content: center;
    }
    padding-bottom: 0;
  }
  @include breakpoint(max-md) {
    padding: 20px 10px 0;
    .btn-grp {
      justify-content: center;
    }
    padding-bottom: 0;
  }
  @include breakpoint(max-sm) {
    .cta-content {
      .title {
        font-size: 28px;
      }
      font-size: 16px;
      .btn-grp {
        .cmn-btn {
          min-width: 0;
        }
      }
    }
  }
}
.slider-wrapper {
  position: relative;
  .slide-next,
  .slide-prev {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include breakpoint(max-xxl) {
      svg {
        width: 500px;
      }
    }
  }
  .slide-prev {
    left: -140px;
    @include breakpoint(max-xxl) {
      left: -80px;
    }
  }
  .slide-next {
    right: -140px;
    @include breakpoint(max-xxl) {
      right: -80px;
    }
  }
}
.slide-item {
  border-radius: 30px;
  &-thumb {
    background: var(--dark-2);
    padding: 24px;
    border-radius: 30px 30px 0 0;
    .thumb {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      padding: 16px;
      background: var(--dark-3);
      border-radius: 24px;
      img {
        width: calc(50% - 8px);
        border-radius: 18px;
        &:first-child {
          width: 100%;
        }
      }
    }
  }
  &-content {
    background: var(--dark-2);
    position: relative;
    z-index: 3;
    transition: all ease 0.3s;
    .content {
      padding: 0 24px 16px;
      .slide-item-author {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 16px;
        img {
          width: 50px;
          @media (max-width: 400px) {
            width: 40px;
          }
        }
        h5 {
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
          margin: 0;
          margin-right: auto;
          @media (max-width: 400px) {
            font-size: 16px;
          }
        }
        .badge {
          border-radius: 15px;
          text-transform: capitalize;
          color: var(--dark);
          font-weight: 700;
          font-size: 15px;
          line-height: 20px;
          @media (max-width: 400px) {
            font-size: 12px;
          }
        }
      }
      .description {
        font-weight: 300;
        font-size: 14px;
        line-height: 200%;
        text-transform: capitalize;
        color: #d0dce7;
        opacity: 0.8;
        letter-spacing: 0.02em;
        margin: 0;
      }
    }
    .floor-info {
      padding-bottom: 24px;
      li {
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: rgba(var(--title-rgb), 0.5);
        padding: 15px 24px;
        @include breakpoint(max-sm) {
          padding: 15px;
        }
        border-top: 1px solid rgba(208, 220, 231, 0.1);
        &:last-child {
          border-bottom: 1px solid rgba(208, 220, 231, 0.1);
        }
      }
    }
  }
  .prebuy-button {
    transition: all ease 0.3s;
    padding: 0 24px 24px;
    overflow: hidden;
    background: var(--dark-2);
    border-radius: 0 0 30px 30px;
    .cmn-btn {
      text-transform: uppercase;
    }
  }
  &:not(:hover) {
    .slide-item-content {
      border-radius: 0 0 30px 30px;
    }
    .prebuy-button {
      opacity: 0;
      transform: translateY(-100%);
    }
  }
}
.z-99 {
  z-index: 99;
}
.section-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 44px;
  position: relative;
  z-index: 999;
  h4 {
    margin: 0;
  }
}
.floating-label-select {
  position: relative;
  label {
    font-weight: 600;
    font-size: 10px;
    line-height: 150%;
    margin: 0 10px -10px 20px;
    display: block;
    span {
      // background: var(--body);
      padding: 0 10px;
      display: inline-flex;
      line-height: 1;
    }
    position: relative;
    z-index: 1;
  }
  min-width: 121px;
  &.multiple {
    min-width: 271px;
  }
  .angle-icon {
    position: absolute;
    right: 8px;
    bottom: 7px;
    z-index: -1;
  }
  &:nth-child(6) {
    z-index: 1;
  }
  &:nth-child(5) {
    z-index: 2;
  }
  &:nth-child(4) {
    z-index: 3;
  }
  &:nth-child(3) {
    z-index: 4;
  }
  &:nth-child(2) {
    z-index: 5;
  }
  &:nth-child(1) {
    z-index: 6;
  }
}
.css-qbdosj-Input,
.css-qbdosj-Input input {
  color: var(--title) !important;
}
.css-b62m3t-container {
  .css-t3ipsp-control,
  .css-13cymwt-control {
    background-color: transparent !important;
    border-radius: 25px !important;
    border-color: rgba(208, 220, 231, 0.6) !important;
    &:focus {
      border-radius: 25px !important;
    }
  }
}
.css-1nmdiq5-menu {
  background: var(--white) !important;
  color: var(--section);
}
.css-1p3m7a8-multiValue {
  margin: 0;
  background: transparent !important;
  .css-wsp0cs-MultiValueGeneric {
    color: var(--title) !important;
    font-size: 12px;
  }
  .css-12a83d4-MultiValueRemove {
    background-color: transparent !important;
  }
}
.css-1hb7zxy-IndicatorsContainer {
  opacity: 0;
}
.css-1dimb5e-singleValue {
  font-size: 12px;
  color: var(--title) !important;
}
.css-1fdsijx-ValueContainer,
.css-3w2yfm-ValueContainer {
  transform: translateY(2px);
  position: relative;
  z-index: 99;
}
.__pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  li {
    width: 35px;
    margin: 0;
    button {
      padding: 0;
      margin: 0;
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--dark-2);
      outline: none;
      border: none;
      border-radius: 50%;
      span {
        display: block;
        transform: translateY(2px);
        color: var(--title);
        font-size: 14px;
        font-weight: 500;
        margin: 0;
      }
      &.active {
        background: var(--base);
        span {
          color: var(--body);
        }
      }
    }
  }
}
.token-card {
  background: var(--dark-2);
  border-radius: 30px;
  .token-card-top-area {
    padding: 24px 24px 16px;
    @media (max-width: 374px) {
      padding: 16px;
    }
    .description {
      margin-top: 16px;
      font-size: 14px;
      line-height: 1.6;
      color: var(--title);
    }
  }
  &-top {
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 21px;
    @media (max-width: 374px) {
      margin-bottom: 12px;
    }
    span {
      display: flex;
      align-items: center;
      padding: 6px 11px 4px;
      border-radius: 14px;
      gap: 8px;
      background: var(--dark-3);
      svg {
        transform: translateY(-2px);
      }
      &.active {
        color: var(--body);
        background: var(--base);
        margin-inline-start: auto;
      }
    }
  }
  &-middle {
    background: var(--dark-3);
    border-radius: 24px;
    padding: 16px;
    display: flex;
    gap: 12px;
    @media (max-width: 374px) {
      padding: 10px;
    }
    .img {
      width: 74px;
      img {
        border-radius: 18px;
        width: 100%;
      }
      .badge {
        margin-top: 20px;
        background: #f0a500;
        width: 100%;
        span {
          color: #12131b;
          padding-left: 3px;
        }
      }
      @media (max-width: 374px) {
        width: 70px;
        .badge {
          margin-top: 15px;
        }
      }
    }
    .cont {
      width: 0;
      flex-grow: 1;
      padding-top: 10px;
      .subtitle {
        font-size: 20px;
        a {
          color: var(--title);
          &:hover {
            color: var(--base);
          }
        }
        @media (max-width: 374px) {
          font-size: 18px;
        }
      }
      .info-grp {
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 20px;
        @media (max-width: 374px) {
          row-gap: 15px;
        }
        .badge {
          width: 40%;
          flex-grow: 1;
        }
      }
    }
    .badge {
      background: var(--dark-2);
      border-radius: 60px;
      display: flex;
      align-items: center;
      text-align: center;
      padding: 6px 10px;
      span {
        transform: translateY(2px);
        width: 0;
        flex-grow: 1;
        text-transform: capitalize;

        color: rgba(208, 220, 231, 0.6);
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
      }
      &.badge-md {
        span {
          font-size: 16px;
        }
      }
    }
  }
  .info {
    li {
      display: flex;
      justify-content: space-between;
      padding: 15px 24px 13px;
      line-height: 14px;
      border-bottom: 1px solid rgba(208, 220, 231, 0.1);
      font-size: 14px;
      font-weight: 700;
      &:first-child {
        border-top: 1px solid rgba(208, 220, 231, 0.1);
      }
    }
  }
  &-bottom {
    padding: 26px 24px 24px;
    font-size: 14px;
    font-weight: 500;
    @media (max-width: 374px) {
      padding: 16px;
    }
  }
}
.countdown {
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 500;
  .subtitle {
    background: var(--dark-3);
    font-size: 10px;
    line-height: 12px;
    padding: 6px 4px 2px;
    text-align: center;
    border-radius: 10px;
    width: 32px;
    text-align: center;
  }
  span:not(.subtitle) {
    transform: translateY(2px);
  }
}
.__progress {
  padding: 4px;
  border-radius: 20px;
  background: rgba(32, 33, 44, 0.9);
  margin: 12px 0;
  .progress-inner {
    background: var(--base);
    background: linear-gradient(212.19deg, #56d984 -20.22%, #196d37 121.54%);
    border-radius: 20px;
  }
  height: 28px;
}
.banner-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 60px;
  padding-bottom: 73px;
  .banner-content {
    width: 100%;
    max-width: 378px;
    .title {
      font-weight: 300;
      font-size: 51px;
      line-height: 66px;
      text-transform: uppercase;
      margin-bottom: 19px;
      strong {
        font-weight: 700;
        display: block;
      }
    }
    .subtitle {
      font-weight: 300;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.01em;
      color: rgba(#d0dce7, 0.5);
      margin-bottom: 36px;
    }
    .btn-grp {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      margin-bottom: 47px;
      .cmn-btn {
        @include breakpoint(xl) {
          min-width: 171px;
          line-height: 28px;
          border-radius: 28px;
        }
      }
      .secondary-btn:not(:hover) {
        background: transparent;
      }
    }
    .trusted-partner {
      a:not(:last-child) {
        &::after {
          display: inline-block;
          content: "|";
          margin: 0 16px;
          color: var(--title);
        }
      }
    }
  }
  .img {
    width: 0;
    flex-grow: 1;
    img {
      width: 100%;
    }
  }
  .right-content {
    width: 100%;
    max-width: 166px;
    display: flex;
    flex-direction: column;
    gap: 34px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: rgba(#d0dce7, 0.4);
    h5 {
      margin-bottom: 4px;
    }
  }
  @include breakpoint(md) {
    @include breakpoint(max-xl) {
      gap: 30px;
      .banner-content {
        max-width: 320px;
        .title {
          font-size: 45px;
          line-height: 55px;
          margin-bottom: 10px;
        }
        .subtitle {
          margin-bottom: 26px;
        }
        .btn-grp {
          margin-bottom: 27px;
        }
      }
      .right-content {
        gap: 20px;
        h5 {
          font-size: 18px;
        }
      }
    }
  }
  @include breakpoint(max-lg) {
    .right-content {
      margin-top: 30px;
      max-width: 100%;
      flex-wrap: wrap;
      flex-direction: row;
      .item {
        flex-grow: 1;
        @include breakpoint(max-md) {
          width: calc(50% - 40px);
        }
      }
    }
  }
  @include breakpoint(max-md) {
    gap: 0;
    .banner-content {
      text-align: center;
      margin: 0 auto;
      .btn-grp {
        justify-content: center;
      }
      .trusted-partner {
        justify-content: center;
      }
    }
    .img {
      width: 100%;
      max-width: 320px;
      margin: -90px auto 25px;
      z-index: -1;
    }
  }
  @include breakpoint(max-sm) {
    padding-top: 40px;
    .banner-content {
      .title {
        font-size: 36px;
        line-height: 40px;
      }
    }
  }
}
.__stake-count-badge-2,
.__stake-count-badge {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: var(--title);
  padding: 15px 24px 10px;
  border-radius: 24px;
  background: var(--dark-2);
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  @include breakpoint(max-sm) {
    font-size: 15px;
    padding: 11px 18px 6px;
  }
}
.__stake-count-badge-2 {
  background: var(--dark-3);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  @include breakpoint(max-sm) {
    // padding: 11px 18px 6px;
  }
}
.chart-card {
  .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title,
  .apexcharts-tooltip.apexcharts-theme-light {
    background: var(--body);
    border-color: var(--border);
  }
}
.chart-card,
.stake-card {
  padding: 25px 20px;
  background: var(--dark-2);
  border-radius: 30px;
  @include breakpoint(max-sm) {
    padding: 15px;
  }
}
.price-chart-header {
  background: var(--dark-3);
  border-radius: 24px;
  padding: 9px;
}
.date-filters {
  gap: 16px;
  @include breakpoint(max-sm) {
    gap: 5px;
  }
  align-items: center;
  li {
    border: 1px solid rgba(208, 220, 231, 0.15);
    &.active {
      color: var(--body);
      background: var(--base);
      border-color: var(--base);
    }
    line-height: 1;
    padding: 8px 12px 4px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 14px;
    cursor: pointer;
  }
}
text {
  fill: rgba(208, 220, 231, 0.8);
}
.staking-leaderboard-list {
  margin-top: 24px;
  font-size: 14px;
  max-height: 240px;
  overflow-y: auto;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 16px 2px;
    input {
      border: none;
      outline: none;
      background: transparent;
      color: var(--title);
      padding: 0;
      display: inline-flex;
      text-align: right;
    }
    background: var(--dark-3);
    border-radius: 100px;
    margin-bottom: 16px;
    .left,
    .right {
      display: flex;
      align-items: center;
      gap: 16px;
      @include breakpoint(max-sm) {
        gap: 4px;
      }
    }
    .serial {
      width: 28px;
      height: 28px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: rgba(18, 19, 27, 0.43);
      border-radius: 100px;
      span {
        transform: translateY(3px);
      }
    }
  }
  @include breakpoint(max-sm) {
    font-size: 12px;
    line-height: 1.2;
    li {
      padding-left: 5px;
      padding-right: 5px;
      .serial {
        width: 20px;
        height: 20px;
      }
    }
  }
  @media (max-width: 374px) {
    font-size: 10px;
  }
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(var(--title-rgb), 0.3);
  width: 4px;
  height: 3px;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(var(--title-rgb), 0.3);
  width: 2px;
  height: 4px;
}
.stake-card {
  .stake-card-menu {
    padding: 8px;
    background: #22232f;
    border-radius: 24px;
    margin-bottom: 24px;
    .inner-div {
      background: var(--dark-3);
      border-radius: 16px;
      display: flex;
      button {
        border-radius: 16px;
        height: 34px;
        width: 50%;
        line-height: 2.4;
        font-weight: 600;
        outline: none;
        border: none;
        box-shadow: none;
        background: transparent;
        &:not(.active) {
          color: var(--white);
        }
        &.active {
          background: var(--base);
        }
      }
    }
  }
  .stake-cart-area {
    margin-top: 24px;
  }
}
.stake-cart-area {
  background: var(--dark-3);
  border-radius: 24px;
  padding: 16px;
  .inc-input {
    width: 0;
    flex-grow: 1;
    margin: 0 16px;
    height: 44px;
    background: var(--dark-2);
    border-radius: 14px;
    border: none;
    outline: none;
    color: var(--white);
    text-align: center;
    &::placeholder {
      color: var(--white);
      font-size: 20px;
    }
    position: relative;
    input {
      opacity: 0;
      position: absolute;
      inset: 0;
    }
  }
  .remainders {
    margin-top: 16px;
  }
}
input[type="number"] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none;
  }
}
.remainders {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  button {
    width: calc(25% - 12px);
    border: none;
    outline: none;
    line-height: 2;
    background: var(--dark-2);
    color: rgba(208, 220, 231, 0.6);
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    padding: 7px;
    border-radius: 14px;
    &.active {
      background: var(--base);
      color: var(--body);
    }
  }
}
.stake-duration {
  margin: 24px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: var(--dark-3);
  border-radius: 24px;
  @include breakpoint(max-sm) {
    margin: 16px 0;
  }
  .subtitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #d0dce7;
    margin-right: auto;
    opacity: 0.8;
  }
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  span {
    padding: 6px 32px;
    background: rgba(#2e3443, 0.7);
    border-radius: 14px;
    text-transform: uppercase;
    &.active {
      color: var(--body);
      background: var(--base);
    }
    @include breakpoint(max-sm) {
      padding: 6px 12px;
      font-size: 12px;
    }
  }
}
.font-sm {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}
.fadeIn {
  animation: fadeIn 0.3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.antex-token-card {
  padding: 24px;
  background: var(--dark-2);
  border-radius: 24px;
  &-banner {
    width: 100%;
    background: rgba(18, 19, 27, 0.49);
    border-radius: 24px;
    @include breakpoint(max-md) {
      height: 100px;
      object-fit: cover;
    }
  }
  &-author {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: -18px 18px 16px;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    .name {
      margin: 0;
    }
    img {
      border: 3px solid var(--dark-2);
      width: 102px;
      border-radius: 22px;
    }
    .content {
      width: 0;
      flex-grow: 1;
      padding-inline-start: 14px;
    }
    @include breakpoint(max-md) {
      margin-top: -10px;
      img {
        width: 80px;
      }
      .content {
        .name {
          font-size: 18px;
        }
      }
    }
    @include breakpoint(max-sm) {
      margin-inline: 0;
      .content {
        .name {
          font-size: 18px;
        }
      }
      img {
        width: 70px;
      }
      .social-icons {
        width: 100%;
      }
    }
  }
  // font-weight: 500;
  // letter-spacing: 0.05em;
  font-size: 16px;
  line-height: 180%;
  color: rgba(#d0dce7, 0.6);
  @include breakpoint(max-md) {
    padding: 18px;
  }
}
.img-icon-btn {
  border: 1.4px solid rgba(208, 220, 231, 0.3);
  border-radius: 999px;
  img {
    width: 38px;
    border-radius: 50%;
    object-fit: cover;
  }
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #d0dce7;
  padding: 6px;
  span {
    padding: 0 15px;
  }
  letter-spacing: 0.005em;
  &:hover {
    background: rgba(18, 19, 27, 0.49);
    color: var(--base);
  }
}
.pre-sale-information-list {
  margin-top: 24px;
  font-size: 14px;
  overflow-y: hidden;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px 8px;
    input {
      border: none;
      outline: none;
      background: transparent;
      color: var(--title);
      padding: 0;
      display: inline-flex;
      text-align: right;
    }
    background: #363b4a;
    font-weight: 600;
    border-radius: 100px;
    margin-bottom: 16px;
    .left,
    .right {
      display: flex;
      align-items: center;
      gap: 16px;
      @include breakpoint(max-sm) {
        gap: 4px;
      }
    }
    .serial {
      width: 28px;
      height: 28px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: rgba(18, 19, 27, 0.43);
      border-radius: 100px;
      span {
        transform: translateY(3px);
      }
    }
  }
  @include breakpoint(max-sm) {
    font-size: 12px;
    line-height: 1.2;
    li {
      padding-left: 5px;
      padding-right: 5px;
      .serial {
        width: 20px;
        height: 20px;
      }
    }
  }
  @media (max-width: 374px) {
    font-size: 10px;
  }
}
.view-all-overlay {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .cmn-btn {
    border-radius: 25px;
    padding: 6px;
    span {
      padding: 3px 28px 0 26px;
      display: inline-block;
    }
  }
  &.active {
    background: linear-gradient(180deg, rgba(46, 52, 67, 0) 0%, #2e3443 98.11%);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 24px;
    padding-bottom: 24px;
    min-height: 80%;
  }
  padding-bottom: 16px;
}
.vesting-information-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  li {
    width: calc(50% - 8px);
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding: 14px 30px;
    background: #363b4a;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    @include breakpoint(max-md) {
      width: 100%;
    }
    @include breakpoint(max-sm) {
      padding-inline: 14px;
      padding-top: 10px;
      padding-bottom: 6px;
      font-size: 13px;
    }
    span:last-child {
      text-align: end;
    }
  }
}
.badge--btn {
  padding: 6px 20px 2px;
}
.__table {
  margin: 0;
  border-spacing: 0 16px;
  border-collapse: separate;
  thead {
    tr {
      th {
        color: rgba(#d0dce7, 0.8);
        font-size: 14px;
        font-weight: 600;
        border: none;
        white-space: nowrap;
      }
    }
  }
  tbody {
    tr {
      td {
        color: var(--title);
        vertical-align: middle;
        border: none;
        background: #363b4a;
        padding: 5px 10px;
        .td {
          display: block;
          transform: translateY(3px);
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: rgba(208, 220, 231, 0.8);
        }
        &:first-child {
          border-radius: 20px 0 0 20px;
        }
        &:last-child {
          border-radius: 0 20px 20px 0;
        }
        .badge--btn {
          width: 94px;
          &[disabled] {
            opacity: 0.4;
            pointer-events: none;
          }
        }
      }
    }
  }
}
.reserve-spots {
  display: flex;
  align-items: center;
  .cmn-btn {
    background: var(--dark-3);
    border: none;
    padding: 6px 0 6px 6px;
    span {
      display: inline-block;
      color: var(--title);
      padding: 0 14px 0 6px;
      transform: translateY(2px);
      font-size: 14px;
      @include breakpoint(max-xl) {
        @include breakpoint(lg) {
          font-size: 11px;
          padding: 0 10px 0 2px;
        }
      }
      @media (max-width: 374px) {
        font-size: 11px;
        padding: 0 10px 0 2px;
      }
    }
    @include breakpoint(max-xl) {
      @include breakpoint(lg) {
        padding: 4px 0 4px 4px;
      }
    }
    position: relative;
    z-index: 1;
    flex-grow: 1;
    justify-content: flex-start;
  }
  .line {
    margin: 0 -8px;
  }
}

.token-leaderboard-list {
  padding: 0 24px;
  font-size: 14px;
  max-height: 240px;
  overflow-y: auto;
  @include breakpoint(max-sm) {
    padding: 0 16px;
  }
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 7px 3px 6px;
    input {
      border: none;
      outline: none;
      background: transparent;
      color: var(--title);
      padding: 0;
      display: inline-flex;
      text-align: right;
    }
    background: var(--dark-3);
    border-radius: 100px;
    margin-bottom: 16px;
    font-size: 14px;
    .icon {
      transform: translateY(-1px);
    }
    .left,
    .right {
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 14px;
      strong {
        white-space: nowrap;
      }
      @include breakpoint(max-sm) {
        gap: 4px;
      }
    }
    input {
      width: 86px;
      padding: 0;
    }
  }
  @include breakpoint(max-sm) {
    font-size: 12px;
    line-height: 1.2;
    li {
      padding-left: 5px;
      padding-right: 5px;
      .serial {
        width: 20px;
        height: 20px;
      }
    }
  }
  @media (max-width: 374px) {
    font-size: 10px;
  }
}
