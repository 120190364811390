.wrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
  background: #2e3443;
  border-radius: 30px;
  padding: 20px;
}
.creatorAndCollection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}
.line {
  width: 2px;
  height: 40px;
  background: #404656;
  border-radius: 20px;
}
.creators,
.collection {
  display: flex;
  align-items: center;
  gap: 10px;
}
.collection {
  padding-right: 30px;
}
.creatorImage {
  width: 77px;
}
.collectionImage {
  width: 50px;
}

.position {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: rgb(208, 220, 231, 0.8);
}
.name {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  color: #d0dce7;
  position: relative;
}
.verified {
  position: absolute;
  top: 50%;
  right: -15px;
  transform: translateY(-50%);
  color: #1d9bf0;
  font-size: 14px;
}

.title {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 150%;
  color: #d0dce7;
  padding-bottom: 8px;
}
.countDownContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: #404656;
  border-radius: 24px;
  padding: 15px 18px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 155%;
  text-transform: capitalize;
  color: rgb(208, 220, 231, 0.8);
}
.infoButtons {
  display: flex;
  gap: 7px;
  align-items: center;
}
.publicRound {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 15px;
  background: #2e3443;
  border-radius: 130px;
}
.publicRoundText {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 133%;
  color: #d0dce7;
}
.circle {
  width: 6px;
  height: 6px;
  display: block;
  border-radius: 50%;
  background: #56d984;
}
.liveButton {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 133%;
  color: #232b32;
  background: #22c55c;
  border-radius: 130px;
  border: none;
  outline: none;
  padding: 5px 15px;
}

.buyContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  background: #404656;
  border-radius: 24px;
  padding: 25px 18px;
}
.buyWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}
.priceWrapper {
  display: flex;
  gap: 7px;
}
.bnbContainer {
  width: 36px;
  height: 50px;
  background: #2e3443;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.bnb {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 140%;
  text-align: justify;
  color: #d0dce7;
}
.usd {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  text-align: justify;
  color: rgb(208, 220, 231, 0.8);
}
.quantityWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 12px;
  background: #2e3443;
  border-radius: 14px;
  cursor: pointer;
  min-height: 44px;
}
.quantity {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #d0dce7;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 156px;
  padding: 13px 10px;
  background: #2e3443;
  border-radius: 14px;
  min-height: 44px;
}
.input {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.06em;

  color: #ecddff;

  width: 100%;
  background: none;
  border: none;
  outline: none;
  height: 100%;
  text-align: center;
}
.icon {
  font-size: 28px;
  color: #ecddff;
}
.button {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 114%;
  text-align: center;
  text-transform: capitalize;
  color: #d0dce7;
  border-radius: 999px;
  background: transparent;
  width: 100%;
  border: 1.4px solid rgba(208, 220, 231, 0.15);
  outline: none;
  padding: 15px 18px;
  cursor: pointer;
}
.activeButton {
  color: #232b32;
  background: #22c55c;
  border: none;
}
@media only screen and (max-width: 1199px) {
  .creatorImage {
    width: 65px;
  }
  .collectionImage {
    width: 45px;
  }
  .name {
    font-size: 16px;
  }
  .collection {
    padding-right: 0;
  }
  .title {
    font-size: 22px;
  }
  .iconContainer,
  .quantity {
    padding: 10px;
    height: 35px;
  }
  .quantityWrapper {
    gap: 6px;
  }
  .quantity {
    width: 100px;
  }
  .bnb {
    font-size: 16px;
  }
}
@media only screen and (max-width: 991px) {
  .collection {
    padding-right: 40px;
  }
}
@media only screen and (max-width: 520px) {
  .wrapper {
    padding: 15px;
  }
  .collection {
    padding-right: 0px;
  }
  .title {
    padding-bottom: 5px;
  }
  .publicRound {
    gap: 4px;
    padding: 4px 10px;
  }
  .liveButton {
    padding: 4px 10px;
  }
  .text {
    font-size: 12px;
  }
  .bnbContainer {
    width: 30px;
  }
  .usd {
    font-size: 12px;
  }
  .quantity {
    font-size: 14px;
    width: 70px;
  }
  .input {
    font-size: 14px;
  }
  .iconContainer {
    padding: 8px;
  }
  .icon {
    font-size: 22px;
  }
  .countDownContainer,
  .contactAddress,
  .buyContainer {
    padding: 15px 13px;
  }
  .button {
    padding: 10px 18px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 400px) {
  .wrapper {
    padding: 12px 5px;
    padding-bottom: 5px;
  }
  .creatorAndCollection {
    padding: 0 10px;
  }
  .creators,
  .collection {
    flex-direction: column;
    align-items: center;
  }
  .position {
    text-align: center;
  }
  .name {
    text-align: center;
    font-size: 14px;
  }
  .title {
    padding-left: 8px;
  }

  .publicRound {
    gap: 4px;

    padding: 4px 8px;
  }
  .publicRoundText {
    font-size: 12px;
  }
  .liveButton {
    padding: 4px 8px;
    font-size: 12px;
  }
  .text {
    font-size: 13px;
  }

  .bnbContainer {
    width: 28px;
  }
  .bnb {
    font-size: 14px;
  }

  .quantity {
    font-size: 12px;
    width: 60px;
  }
  .input {
    font-size: 12px;
  }
  .iconContainer {
    padding: 5px;
  }
  .icon {
    font-size: 20px;
  }
  .countDownContainer,
  .contactAddress,
  .buyContainer {
    padding: 13px 10px;
  }
  .button {
    font-size: 14px;
  }
}
