.myAllocation {
  background: #2e3443;
  border-radius: 30px;
  padding: 25px 18px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.title {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 145%;
  text-transform: capitalize;
  color: rgb(208, 220, 231, 0.8);
  padding-bottom: 3px;
}
.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}
.text {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 145%;
  color: rgb(208, 220, 231, 0.5);
}
@media only screen and (max-width: 520px) {
  .myAllocation {
    padding: 20px 15px;
  }
}
