/* .tokenCardContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  justify-content: center;
} */

.live {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 140%;

  color: rgba(34, 197, 92, 0.5);
  cursor: pointer;
}
.liveI {
  position: relative;
}
.liveI::before {
  content: "";
  width: 16px;
  height: 16px;
  background: #22c55c;
  border-radius: 50%;
  position: absolute;
  top: 8.2%;

  left: 50%;
  transform: translateX(-50%);
}
.upcoming,
.ended {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  text-align: center;

  color: rgba(34, 197, 92, 0.5);
  position: relative;
  cursor: pointer;
}
.upcomingI {
  position: relative;
}
.upcomingI::before {
  content: "";
  width: 16px;
  height: 16px;
  background: #f0c039;
  border-radius: 50%;
  position: absolute;
  top: 5%;

  left: 50%;
  transform: translateX(-50%);
}
.endedD::before {
  content: "";
  width: 16px;
  height: 16px;
  background: rgba(208, 220, 231, 0.6);
  border-radius: 50%;
  position: absolute;
  top: 0%;
  right: -2px;
}
.activeFilter {
  color: #d0dce7;
}
@media only screen and (max-width: 575px) {
  .liveI {
    font-size: 36px;
  }
  .liveI::before {
    content: "";
    width: 10px;
    height: 10px;

    top: 22%;
  }
  .upcoming,
  .ended {
    font-size: 32px;
  }
  .upcomingI::before {
    width: 10px;
    height: 10px;

    top: 10%;
  }
  .endedD::before {
    width: 10px;
    height: 10px;

    top: 10%;
    right: -1px;
  }
} ;
