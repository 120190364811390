:root {
	--pie-width: 15px;
}
.semi-donut {
	width: 100%;
	aspect-ratio: 2;
	position: relative;
	text-align: center;
	color: #fff;
	font-size: 22px;
	font-weight: 600;
	border-radius: 150px 150px 0 0;
	overflow: hidden;
	color: var(--fill);
	display: flex;
	align-items: flex-end;
	justify-content: center;
	box-sizing: border-box;
	&:before,
	&:after {
		content: "";
		width: 100%;
		aspect-ratio: 2;
		border: var(--pie-width) solid var(--fill);
		border-top: none;
		position: absolute;
		transform-origin: 50% 0% 0;
		border-radius: 0 0 300px 300px;
		box-sizing: border-box;
		left: 0;
		top: 100%;
	}
	&:before {
		border-color: var(--dark-2);
		transform: rotate(180deg);
	}
	&:after {
		z-index: 3;
		animation: 1s fillGraphAnimation ease-in;
		transform: rotate(calc(1deg * (var(--percentage) * 1.8)));
	}
	&:hover {
		&:after {
			opacity: 0.8;
			cursor: pointer;
		}
	}
	.semi-txt {
		font-size: 10px;
		font-weight: 400;
		display: block;
		line-height: 1;
		color: var(--title);
	}
}

.multi-graph {
	width: 100%;
	aspect-ratio: 2;
	position: relative;
	color: #fff;
	font-size: 22px;
	font-weight: 600;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	overflow: hidden;
	box-sizing: border-box;
	max-width: 244px;
	margin: 0 auto;
	&:before {
		content: "";
		width: 100%;
		aspect-ratio: 2;
		border: var(--pie-width) solid rgba(0, 0, 0, 0.15);
		border-bottom: none;
		position: absolute;
		box-sizing: border-box;
		transform-origin: 50% 0%;
		border-radius: 300px 300px 0 0;
		left: 0;
		top: 0;
	}
	.graph {
		width: 100%;
		aspect-ratio: 2;
		border: var(--pie-width) solid var(--fill);
		border-top: none;
		position: absolute;
		transform-origin: 50% 0% 0;
		border-radius: 0 0 300px 300px;
		left: 0;
		top: 100%;
		z-index: 5;
		animation: 1s fillGraphAnimation ease-in;
		transform: rotate(calc(1deg * (var(--percentage) * 1.8)));
		box-sizing: border-box;
		cursor: pointer;
		&:after {
			// content: attr(data-name) ;
			content: attr(data-name) " " counter(varible) "%";
			counter-reset: varible var(--percentage);
			background: var(--fill);
			box-sizing: border-box;
			border-radius: 2px;
			color: #fff;
			font-weight: 200;
			font-size: 12px;
			line-height: 20px;
			padding: 3px 5px 0;
			top: 0px;
			position: absolute;
			left: 0;
			transform: rotate(calc(-1deg * var(--percentage) * 1.8))
				translate(-30px, 0px);
			transition: 0.2s ease-in;
			transform-origin: 0 50% 0;
			opacity: 0;
		}
		&:hover {
			opacity: 0.8;
			&:after {
				opacity: 1;
				left: 30px;
			}
		}
	}
}

@keyframes fillAnimation {
	0% {
		transform: rotate(-45deg);
	}
	50% {
		transform: rotate(135deg);
	}
}

@keyframes fillGraphAnimation {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(180deg);
	}
}
.dognut-inner-content {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: rgba(#d0dce7, 0.6);
	width: 65%;
	max-width: 150px;
	aspect-ratio: 1;
	margin: 0 auto;
	background: #404656;
	padding: 20px;
	border-radius: 50%;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 40px;
	h5 {
		font-weight: 700;
		font-size: 20px;
		line-height: 28px;
		text-align: center;
		color: #d0dce7;
		margin-top: 8px;
		margin-bottom: 0;
	}
}
.token-items {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: space-between;
	row-gap: 30px;
	column-gap: 10px;
	@media screen and (min-width: 1200px) {
		padding: 10px;
	}
	.token-item {
		display: flex;
		align-items: center;
		gap: 13px;
		font-weight: 500;
		font-size: 12px;
		line-height: 1.4;
		color: var(rgba(#d0dce7, 0.8));
		h6 {
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
			margin: 0;
		}
		@media (max-width: 991px) {
			width: calc(50% - 5px);
			max-width: 160px;
		}
	}
}
